<template>
  <div>
    <div class="row">
      <div class="col-12 p-1">
        <div class="d-flex justify-content-start align-items-center">
          <dx-util-validation-group ref="saleDataRef">
            <div class="d-flex align-items-center">
            <div class="px-half">
              <dx-util-check-box
                id="applyAll"
                v-model="applyAll"
                class="text-warning font-weight-bolder"
                text="Apply All"
              />
            </div>
            <div v-if="!applyAll" class="px-half">
              <dx-util-date-box
                id="startDate"
                v-model="startDate"
                placeholder="Start Date"
                :max="endDate"
                type="date"
                date-serialization-format="yyyy-MM-dd"
              />
            </div>
            <div v-if="!applyAll" class="px-half">
              <dx-util-date-box
                id="endDate"
                v-model="endDate"
                placeholder="End Date"
                :min="startDate"
                type="date"
                date-serialization-format="yyyy-MM-dd"
              />
            </div>
            <div class="px-half">
              <dx-util-select-box
                v-model="selectedStore"
                placeholder="Select Store"
                :data-source="stores"
                display-expr="text"
                value-expr="value"
                :show-clear-button="true"
              />
            </div>
          </div>
          </dx-util-validation-group>
          <dx-util-button text="Submit" type="success" class="px-2 my-half" @click="submitData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import shipService from '@/http/requests/ship/shipService'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import commonEntityService from '@/http/requests/common/commonEntityService'
import amazonReportService from '@/http/requests/reports/amazonReportService'
// import fbmService from '@/http/requests/fbm/fbmService'

export default {
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { userCompanyType } = useCurrentUser()
    return {
      userCompanyType,
    }
  },
  data: () => ({
    startDate: '',
    endDate: '',
    applyAll: false,
    selectedStore: null,
    stores: [],
  }),
  computed: {
    validationGroup() {
      return this.$refs.saleDataRef.instance
    },
  },
  watch: {
    csvData: () => {},
  },
  mounted() {
    this.getStores()
  },
  methods: {
    getStores() {
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const { data } = result
        this.setStores(data)
      })
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      data.forEach(element => {
        this.stores.push({ value: element.id, text: element.name })
      })
    },
    async submitData() {
      if (this.form === null) {
        Notify.danger('Please upload csv or excel file.')
        return
      }
      if (!this.applyAll && (!this.startDate || !this.endDate)) {
        Notify.error('Start date and end date cannot be empty!')
        return
      }
      const response = await this.validationGroup.validate()
      const payload = {
        applyAll: this.applyAll,
        startDate: this.applyAll ? '' : this.startDate,
        endDate: this.applyAll ? '' : this.endDate,
        storeId: this.selectedStore,
        itemInfo: this.csvData,
      }
      if (response.isValid) {
        const result = await amazonReportService.updateBuyCost(payload)
        Notify.success(`buy cost has been updated, Item count: ${result}`)
        this.$emit('emit-upload-form-saved')
        this.closePopup()
      }
    },
  },
}
</script>
