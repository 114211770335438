<template>
  <div class="row">
    <!-- SALES DATA FILE UPLOAD LIST -->
    <div v-if="errorSummary.length > 0" class="col-md-12 mb-1">
      <dx-util-list
      :data-source="errorSummary"
      :grouped="true"
      :collapsible-groups="true"
      show-scrollbar="always"
      :hover-state-enabled="false"
      :focus-state-enabled="false"
      :element-attr="listAttributes"
      />
    </div>
    <div v-else class="col-md-12">
      <fileupload-save v-if="csvData" :csv-data="grid.tableData" />
    </div>
    <div class="col-md-12">
      <dx-data-grid
          id="fileUploadItemsGrid"
          ref="mainGrid"
          :height="gridSetHeight"
          :data-source="grid.tableData"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          @row-prepared="setErrorRow"
      >
        <dx-column data-field="asin" caption="ASIN" />
        <dx-column data-field="msku" caption="MSKU" />
        <dx-column data-field="cost" caption="Cost" />
        <dx-paging :page-size="500" />
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import {
  isValidMsku, isValidAsin, isRequiredAny, isCurrency,
} from '@/views/apps/ship/validationItem.js'
import FileUploadSave from '../file-upload-save/FileUploadSave.vue'

export default {
  components: {
    'fileupload-save': FileUploadSave,
  },
  mixins: [GridBase],
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    grid: {
      tableData: [],
    },
    noValidData: false,
    listAttributes: {
      id: 'errorList',
    },
    errorSummary: [],
  }),
  mounted() {
    this.initData()
  },
  methods: {
    gridSetHeight() {
      return window.innerHeight / 1.4
    },
    initData() {
      this.csvData.forEach((item, index) => {
        const item1 = JSON.parse(JSON.stringify(item).replace(/"\s+|\s+"/g, '"'))
        const result = JSON.parse(JSON.stringify(this.checkDataItem(item1, index)))
        this.grid.tableData.push(result)
      })
    },
    setErrorRow(e) {
      if (e.rowType === 'data' && e.data.rowVariant === true) {
        e.rowElement.style.backgroundColor = '#ea5455'
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '')
      }
    },
    checkDataItem(item, index) {
      const row = this.normalizeRowData(item)
      row.Order = index + 1
      const message = []
      if (!isValidAsin(row.asin) && !isValidMsku(row.msku)) message.push('Please enter a valid ASIN value.')
      if (!isValidMsku(row.msku) && !isValidAsin(row.asin)) message.push('Please enter a valid MSKU value.')
      if (!isRequiredAny(row.asin, row.msku)) {
        message.push('Please enter ASIN or MSKU value.')
      }
      if (!isCurrency(row.cost)) {
        if (!row.cost) row.cost = ''
        message.push('Please enter a Cost value.')
      }
      if (parseFloat(row.cost) === 0) {
        message.push('Please enter valid cost.')
      }
      if (message.length > 0) {
        row.rowVariant = true
        this.errorSummary.push({
          key: `Line ${index + 1}: There are ${message.length} errors in line ${index + 1}`,
          items: message,
        })
      }
      return row
    },
    normalizeRowData(item) {
      item.cost = String(item.cost).replace(',', '.')
      item.cost = parseFloat(item.cost.replace(/[^.0-9]/g, '')).toFixed(2)
      return item
    },
  },
}
</script>

<style lang="scss">
  #errorList {
    .dx-scrollable-content {
      padding-right: 0px !important;
    }
    .dx-list-group {
      background-color: rgba(255, 255, 255,1.0);
      .dx-list-group-header {
        color: #c0392b;
        padding: 10px 10px 10px !important;
        border-bottom: 1px solid rgba(164, 176, 190,1.0) !important;
        border-top: 1px solid rgba(164, 176, 190,1.0) !important;
        &::before{
          border-top-color: #c0392b;
        }
      }
      .dx-list-item{
        border-top:  1px solid rgba(164, 176, 190,1.0) !important;
        .dx-item-content {
          &.dx-list-item-content{
            color: #c0392b;
          }
        }
      }
    }
  }
</style>>
