<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isUploaded" class="col-lg-12 px-0">
        <div class="card p-half">
          <div class="card-body">
            <div class="card-title">
              Import Item Costs
            </div>
            <div class="alert alert-danger p-1" role="alert">
              <h4 class="text-warning">
                <p-icon name="bi-exclamation-circle-fill" class="pr-1" />
                Please always use the latest template. Last update time
                25/05/2024
                <div class="badge badge-warning badge-glow mx-2">
                  ATTENTION
                </div>
              </h4>
            </div>
            <div class="row mb-1 justify-content-between mr-half">
              <div class="ml-1 border border-primary rounded">
                <span class="px-2">Choose your sales file ...</span>
                <input ref="fileInput" type="file" accept=".xlsx, .xls, .csv" hidden @change="onChange">
                <dx-util-button
                  class="py-half px-2 "
                  text="Browse"
                  type="success"
                  @click.native="$refs.fileInput.click()"
                />
              </div>
              <dx-util-button
                class="py-half px-2 "
                text="Download Template"
                icon="bi bi-filetype-xlsx"
                type="success"
                @click.native="downloadExcelTemplate"
              />
            </div>
          </div>
          <div class="row px-1 mx-half">
            <dx-data-grid
              id="instructionsGrid"
              ref="instructionsGridRef"
              class="mt-1"
              :data-source="instructions"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
            >
              <dx-paging :enabled="false" />
              <dx-column
                data-field="field_name"
                cell-template="fieldNameTemplate"
                width="auto"
              />
              <dx-column
                data-field="required"
                cell-template="requiredTemplate"
                :width="120"
                :sort-index="0"
                sort-order="desc"
              />
              <dx-column
                data-field="instructions"
                cell-template="titleCellTemplate"
              />
              <template #titleCellTemplate="{data}">
                <span class="dx-product-title">
                  {{ data.value }}
                </span>
              </template>
              <template #fieldNameTemplate="{data}">
                <span class="font-weight-bold text-primary">
                  {{ data.value }}
                </span>
              </template>
              <template #requiredTemplate="{data}">
                <span
                  :class="
                    `text-${data.value === 'Required' ? 'danger' : 'warning'}`
                  "
                >
                  {{ data.value }}
                </span>
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <div v-else class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h4>Uploaded Data</h4>
              <p-icon role="button" name="bi-x-lg" @click.native="closeCard" />
            </div>
            <div>
              <xlsx-read :file="file">
                <xlsx-json>
                  <template #default="{collection}">
                    <fileupload-list v-if="collection" :csv-data="collection" />
                  </template>
                </xlsx-json>
              </xlsx-read>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxRead, XlsxJson } from 'vue-xlsx'
import { Notify } from '@robustshell/utils'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import FileUploadList from './file-upload-list/FileUploadList.vue'

export default {
  components: {
    XlsxRead,
    XlsxJson,
    'fileupload-list': FileUploadList,
  },
  data() {
    return {
      file: null,
      isUploaded: false,
      saveForm: false,
      fields: ['id', 'field_name', 'required', 'instructions'],
      instructions: [
        {
          id: 1,
          field_name: 'ASIN',
          required: 'Optional',
          instructions: 'Product ASIN',
        },
        {
          id: 2,
          field_name: 'MSKU',
          required: 'Optional',
          instructions: 'Please enter MSKU. MSKU is required if ASIN is not provided',
        },
        {
          id: 3,
          field_name: 'Cost',
          required: 'Required',
          instructions: 'Please enter the cost of the item. ',
          notes: '',
        },
      ],

    }
  },
  methods: {
    onChange(event) {
      const file = event.target?.files ? event.target?.files[0] : null
      const name = file.name
      const lastDot = name.lastIndexOf('.')
      const ext = name.substring(lastDot + 1)
      if (ext !== 'xlsx' && ext !== 'xls' && ext !== 'csv') {
        Notify.warning(
          'You can upload only xlsx, xls or csv files. Please always use PSH Excel Sales Template.',
        )
        this.downloadExcelTemplate()
        return
      }

      this.file = file
      if (this.file != null) {
        this.isUploaded = !this.isUploaded
      }
    },
    closeCard() {
      this.isUploaded = !this.isUploaded
      this.file = null
    },
    downloadExcelTemplate() {
      amazonReportService.downloadTemplate().then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/csv' }))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'sales_import_template.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
}
</script>
